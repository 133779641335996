const addYearsAndGetFormattedDate = yearsToAdd => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // Get current date
  let date = new Date()

  // Add desired years
  date.setFullYear(date.getFullYear() + yearsToAdd)

  // Format the date
  const dayName = days[date.getUTCDay()]
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = months[date.getUTCMonth()]
  const year = date.getUTCFullYear()
  const time = date.toUTCString().split(' ')[4]

  return `${dayName}, ${day}-${month}-${year} ${time} GMT`
}

export default addYearsAndGetFormattedDate
