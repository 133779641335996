import logo from 'assets/logo.png'
import { SESSAMI_EMAILS_REGEX } from 'consts/index'
import { signInWithEmailAndPassword } from 'firebase/auth'
import useFetchData from 'hooks/useFetchData'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Loading } from 'notiflix/build/notiflix-loading-aio'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { auth } from 'services/firebaseClient'
import { setUserAuth } from 'store/reducers'
import setCookie from 'utils/SetCookie'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const { fetchLoggedInUserData } = useFetchData()
  const router = useRouter()

  const handleSubmit = async event => {
    event.preventDefault()
    Loading.standard()
    // if (WHITELISTED_EMAIL.includes(email)) {
    if (SESSAMI_EMAILS_REGEX.test(email)) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        const user = userCredential?.user

        if (user.emailVerified) {
          dispatch(setUserAuth(true))
          await fetchLoggedInUserData(email)

          // Set cookie in order to keep track of auth state for route protection
          setCookie('adminUser', user.uid, { path: '/' })

          // Ensure the cookie is set before navigating
          setTimeout(() => router.push('/'), 0)
        } else {
          toast.error('Please verify your email before logging in.')
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        Loading.remove()
      }
    } else {
      toast.error('You are not authorized to access this page', { id: 'NA' })
      Loading.remove()
    }
  }

  return (
    <div className='hero min-h-screen bg-black fixed top-0 left-0 w-screen'>
      <div className='hero-content text-center'>
        <div className='max-w-md'>
          <div className='flex justify-center mb-8'>
            <Image src={logo} className='w-12' alt='logo-img' height={300} width={300} />
          </div>
          <h1 className='text-2xl uppercase tracking-[5px] font-bold text-white mb-4'>
            Sessami Admin
          </h1>

          <form className='flex flex-col w-full' onSubmit={handleSubmit}>
            <div className='flex flex-col space-y-2'>
              <input
                type='email'
                placeholder='Email'
                className='input border border-1 border-white bg-transparent text-white w-full'
                onChange={e => setEmail(e.target.value)}
              />
              <input
                type='password'
                placeholder='Password'
                className='input border border-1 border-white bg-transparent text-white w-full'
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <input type='submit' className='btn btn-outline btn-secondary mt-8' value='→ Login' />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignIn
