export const DATE_FORMAT_SHORT = 'D MMM YY'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A'
export const HUMAN_FRIENDLY_DATE_TIME_FORMAT = 'ddd, MMM D, hh:mm A '
export const HUMAN_FRIENDLY_DATE_FORMAT = 'ddd, MMM D'
export const MONTH_DAY_FORMAT = 'MMM DD'
export const PRECISE_TIME = 'DD/MM/YYYY hh:mm:ss A'
export const TIME_FORMAT = 'hh:mm A'

export const STRIPE_PERCENT = 0.015
export const STRIPE_FIXED = 0.2
export const TICKET_PROCESSING_PERCENTAGE = 6 // 6%
export const TICKET_PROCESSING_FIXED = 0.2 // GBP

export const SESSAMI_PROFIT = 0.045
export const SESSAMI_CHARGE = 0.06
export const SESSAMI_PERCENT = 0.06
export const SESSAMI_FIXED = 0.2
export const PROFIT_PERCENT = SESSAMI_PERCENT - STRIPE_PERCENT
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024

export const SESSAMI_EMAILS_REGEX = /^[a-zA-Z0-9._+-]+@sessami\.co$/
export const WHITELISTED_EMAIL = [
  // shared inbox
  'hello@sessami.co',
  'support@sessami.co',

  // individual emails
  'svl@sessami.co',
  'luke@sessami.co',
  'keshav@sessami.co',
  'joe@sessami.co',

  // devs
  'doleshorkhad@gmail.com',
  'nishanbudhathoki2266@gmail.com',
  'rowan.tamang@gmail.com',
  'surajpulamim123@gmail.com',
]

export const HOST_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'development' ? 50 : 40

export const EVENT_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 100 : 10

export const LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'development' ? 50 : 300

export const EVENT_PLACEHOLDER_IMAGE =
  process.env.NODE_ENV === 'production'
    ? 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/eventPlaceholder.png?alt=media&token=b1a8e325-e1ce-4d09-968e-f05fa6d85717'
    : 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/eventPlaceholder.png?alt=media&token=4c69f4ac-80e5-4d31-a708-e5a5d46b46f7'

export const USER_PLACEHOLDER_IMAGE =
  process.env.NODE_ENV === 'production'
    ? 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/userPlaceholder.png?alt=media&token=81abdfee-1ca7-4645-b0eb-de88f618d2a0'
    : 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/userPlaceholder.png?alt=media&token=81abdfee-1ca7-4645-b0eb-de88f618d2a0'

export const FILTER_OPTIONS = [
  { value: 'all', label: `All ` },
  { value: 'cancel', label: 'Cancelled ' },
]

export const STORAGE_GALLERY_FOLDER = 'gallery'
export const STORAGE_UPLOADEDBY_TAG = 'gallery-admin'
export const IMAGE_RESOLUTION_REGEX = /_(\d+)x(\d+)/

export const EURO_COUNTRIES = [
  'IT',
  'FR',
  'DE',
  'ES',
  'PT',
  'GR',
  'IE',
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'HU',
  'HR',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'RO',
  'SE',
  'SI',
  'SK',
]
